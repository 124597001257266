

/* .nav-link {
  color:'#1E1E1E';
  text-decoration-color: '#1E1E1E';
} */
i path {
  stroke: red;
  stroke-width: 30px;
}
a {
  text-decoration: none;
  cursor: pointer;
}
.navbar-nav .nav-item .active h6 {
  color:'#67BEFA';
}
.navbar-nav .nav-item .nav-link h6 {
  color:'#16307D';
}
/* .navbar-nav .nav-item .active h6 {
  color:'#67BEFA';
} */
.navbar-nav .nav-item a h6:hover {
  color:'red';
}
@media (max-width: 991.98px) {
  .alert-div {
    margin-bottom: 10px;
  }
}


